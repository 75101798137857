import React, { useState, useRef } from 'react'
import * as alertStyles from '../SharedCss/alert.css'
import * as styles from '../SharedCss/form.css'
import { signInExternalHost } from '../ExternalHosts'
import { signIn } from '../Auth'
import Footer from '../SharedComponents/Footer'
import LoadingBar from 'react-top-loading-bar'
import ALERT_TYPES from '../SharedConstants/AlertTypes'
import MfaForm from './MfaForm'
require('dotenv').config()

export default function NewPasswordForm (props) {
  const { cognitoUser, sessionUserAttributes } = props
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [confirmation, setConfirmation] = useState('')
  const [alertType, setAlertType] = useState('')
  const [showMfaForm, setShowMfaForm] = useState(false)
  const [session, setSession] = useState('')
  const [destination, setDestination] = useState('')
  const loadingRef = useRef(null)

  const handleSubmit = (e) => {
    e.preventDefault()

    if (password !== confirmation) {
      setAlertType(ALERT_TYPES.UPDATE_FAILED)
      setErrorMessage('Password and password confirmation do not match')
      return false
    }

    if (password.trim().length === 0) {
      setAlertType(ALERT_TYPES.UPDATE_FAILED)
      setErrorMessage('Please enter your password')
      return false
    }

    loadingRef.current.continuousStart()

    // Make sure these attributes not submitted along with the password reset request, otherwise
    // it will return an error.
    delete sessionUserAttributes.phone_number
    delete sessionUserAttributes.phone_number_verified

    cognitoUser.completeNewPasswordChallenge(password, sessionUserAttributes, {
      onSuccess: (tokens) => {
        signIn(tokens)
        signInExternalHost().then(() => {
          loadingRef.current.complete()
        })
      },
      mfaRequired: function (codeDeliveryDetails, params) {
        setSession(cognitoUser)
        setDestination(params.CODE_DELIVERY_DESTINATION)
        setShowMfaForm(true)
      },
      onFailure: (err) => {
        loadingRef.current.complete()
        setAlertType(ALERT_TYPES.UPDATE_FAILED)
        setErrorMessage(err.message)
      }
    })
  }

  const renderAlert = () => {
    if (alertType === ALERT_TYPES.UPDATE_FAILED) {
      return (
        <div className={[alertStyles.alert, alertStyles.error].join(' ')}>
          { errorMessage }
        </div>
      )
    }
  }

  if (showMfaForm) {
    return <MfaForm
      cognitoUser={ session }
      destination={ destination } />
  } else {
    return <div className={styles.container}>
      { renderAlert() }
      <LoadingBar color='#f11946' ref={loadingRef} />

      <div className={styles.header}>
        <div className={styles.logo}>
          <h1>Blueleaf</h1>
        </div>

        <div className={styles.header_text}>
          <h2>Set your new password</h2>
        </div>
      </div>

      <div className={styles.form_container}>
        <form id="new-password-form" onSubmit={handleSubmit}>
          <div className={styles.fieldset}>
            <label className={styles.label}>
              Password
            </label>
            <input className={styles.input_field} id="password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
          </div>

          <div className={styles.fieldset}>
            <label className={styles.label}>
              Confirm your new password
            </label>
            <input className={styles.input_field} type="password" value={confirmation} onChange={(e) => setConfirmation(e.target.value)} />
          </div>

          <input id="submit-btn" className={styles.submit_btn} type="submit" value="Set" />
          <a id="sign-in-btn" className={styles.link} href="/" >Back to sign in</a>
        </form>
      </div>

      <Footer />
    </div>
  }
}
