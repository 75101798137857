import React, { useState, useRef } from 'react'
import * as alertStyles from '../SharedCss/alert.css'
import * as styles from '../SharedCss/form.css'
import { getAuthenticatedUser, getAuthenticatedUserData } from '../Auth'
import Menu from '../Menu'
import Logger from '../Logger'
import ALERT_TYPES from '../SharedConstants/AlertTypes'
import Footer from '../SharedComponents/Footer'
import LoadingBar from 'react-top-loading-bar'
import { trackPendo } from '../pendo'
require('dotenv').config()

export default function ChangePassword () {
  const cognitoUser = getAuthenticatedUser()
  const userData = getAuthenticatedUserData()
  const [alertType, setAlertType] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [password, setPassword] = useState('')
  const [oldPassword, setOldPassword] = useState('')
  const [confirmation, setConfirmation] = useState('')
  const loadingRef = useRef(null)
  const userId = userData.getAttr('custom:bl_id')

  trackPendo('Visit Change Password', { userId: userId })

  const handleSubmit = async (e) => {
    Logger.start('Update Password')
    setAlertType('')
    e.preventDefault()

    if (password !== confirmation) {
      setAlertType(ALERT_TYPES.UPDATE_FAILED)
      setErrorMessage('Password and confirmation password do not match')
      return
    }

    loadingRef.current.continuousStart()

    cognitoUser.changePassword(oldPassword, password, (err, result) => {
      loadingRef.current.complete()

      if (err) {
        Logger.error('Update Password', err)
        setAlertType(ALERT_TYPES.UPDATE_FAILED)
        setErrorMessage(err.message || JSON.stringify(err))

        trackPendo('Failed to change Password', { userId: userId, error: err.message })
      } else {
        Logger.done('Update Password')
        setAlertType(ALERT_TYPES.UPDATE_SUCCESSFULLY)

        trackPendo('Changed password')
      }
    })
  }

  const renderAlert = () => {
    if (alertType === ALERT_TYPES.UPDATE_FAILED) {
      return (
        <div className={[alertStyles.error, alertStyles.render_with_menu, alertStyles.alert].join(' ')}>
          { errorMessage }
        </div>
      )
    } else if (alertType === ALERT_TYPES.UPDATE_SUCCESSFULLY) {
      return (
        <div className={[alertStyles.success, alertStyles.render_with_menu, alertStyles.alert].join(' ')}>
          Change password successfully.
        </div>
      )
    }
  }

  return <div className={styles.container}>
    { renderAlert() }
    <LoadingBar color='#f11946' ref={loadingRef} />

    <Menu highlighted="change_password"/>
    <div>
      <div className={styles.header}>
        <div className={styles.logo}>
          <h1>Blueleaf</h1>
        </div>

        <div className={styles.header_text}>
          <h2>Change Your Password</h2>
        </div>
      </div>

      <div className={styles.form_container}>
        <form id="new-password-form" onSubmit={handleSubmit}>
          <div className={styles.fieldset}>
            <label className={styles.label}>
              Old Password
            </label>
            <input className={styles.input_field} id="old-password" type="password" value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} />
          </div>

          <div className={styles.fieldset}>
            <label className={styles.label}>
              New Password
            </label>
            <input className={styles.input_field} id="password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
          </div>

          <div className={styles.fieldset}>
            <label className={styles.label}>
              Confirm New Password
            </label>

            <input className={styles.input_field} id="password-confirmation" type="password" value={confirmation} onChange={(e) => setConfirmation(e.target.value)} />
          </div>

          <input id="submit-btn" className={styles.submit_btn} type="submit" value="Save" />
        </form>
      </div>

      <Footer />
    </div>
  </div>
}
