.custom_login_container *{
  box-sizing: revert;
}

.custom_login_container h1,h2,h3,h4,h5{
  margin: revert;
}

.custom_login_container {
  margin-top: 20px;
  margin-left: 40px;
}
