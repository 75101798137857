import React from 'react'
import * as styles from './footer.css'

export default function Footer () {
  const year = new Date().getFullYear()

  return (
    <div className={styles.footer}>
      <span className={styles.legal}>
        {`2010-${year} Blueleaf Wealth, Inc. All rights reserved. Patent pending.`}
      </span>
    </div>
  )
}
