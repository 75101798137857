import React, { useState, useRef } from 'react'
import * as alertStyles from '../SharedCss/alert.css'
import * as styles from '../SharedCss/form.css'
import { signOut, getAuthenticatedUser, getAuthenticatedUserData } from '../Auth'
import MessageBus from '../MessageBus'
import Flash from '../Flash'
import Menu from '../Menu'
import Logger from '../Logger'
import ALERT_TYPES from '../SharedConstants/AlertTypes'
import Footer from '../SharedComponents/Footer'
import LoadingBar from 'react-top-loading-bar'
import { trackPendo } from '../pendo'
require('dotenv').config()

export default function UpdateEmail (props) {
  const { CognitoIdentityLib } = props
  const cognitoUser = getAuthenticatedUser()
  const userData = getAuthenticatedUserData()
  const email = userData.getAttr('email')
  const [alertType, setAlertType] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [newEmail, setNewEmail] = useState(email)
  const [password, setPassword] = useState('')
  const [confirmation, setConfirmation] = useState('')
  const loadingRef = useRef(null)
  const userId = userData.getAttr('custom:bl_id')

  const userPool = new CognitoIdentityLib.CognitoUserPool({
    UserPoolId: process.env.USER_POOL_ID,
    ClientId: process.env.COGNITO_APP_ID
  })

  trackPendo('Visit Update Email', { userId: userId })

  const constructAttrs = () => {
    return [
      new CognitoIdentityLib.CognitoUserAttribute({
        Name: 'nickname',
        Value: userData.getAttr('nickname')
      }),
      new CognitoIdentityLib.CognitoUserAttribute({
        Name: 'phone_number',
        Value: userData.getAttr('phone_number')
      }),
      new CognitoIdentityLib.CognitoUserAttribute({
        Name: 'given_name',
        Value: userData.getAttr('given_name')
      }),
      new CognitoIdentityLib.CognitoUserAttribute({
        Name: 'family_name',
        Value: userData.getAttr('family_name')
      }),
      new CognitoIdentityLib.CognitoUserAttribute({
        Name: 'custom:bl_id',
        Value: userData.getAttr('custom:bl_id')
      }),
      new CognitoIdentityLib.CognitoUserAttribute({
        Name: 'custom:chargebee_id',
        Value: userData.getAttr('custom:chargebee_id')
      })
    ]
  }

  const signUpUserWithNewEmail = () => {
    Logger.start('Update Email', { newEmail: newEmail, oldEmail: email })
    const blId = userData.getAttr('custom:bl_id')

    Logger.start('Sign up new user', { newEmail: newEmail })
    userPool.signUp(newEmail, password, constructAttrs(), null, (err, user) => {
      if (err) {
        setAlertType(ALERT_TYPES.UPDATE_FAILED)
        setErrorMessage(err.message || JSON.stringify(err))
        return
      }

      Logger.done('Sign up new user', { newEmail: newEmail })

      MessageBus.emitUserAttrsChange({
        username: user.userSub,
        bl_id: blId
      })

      Logger.start('Delete old user', { oldEmail: email })
      cognitoUser.deleteUser((err) => {
        loadingRef.current.complete()

        if (err) {
          setAlertType(ALERT_TYPES.UPDATE_FAILED)
          setErrorMessage(err.message || JSON.stringify(err))
          return
        }

        Logger.done('Delete old user', { oldEmail: email })
        Flash.setMessage('Update email successfully, please check your email and click the confirmation link')

        trackPendo('Updated email', { userId: userId })
        signOut()
      })
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    if (password !== confirmation) {
      setAlertType(ALERT_TYPES.UPDATE_FAILED)
      setErrorMessage('Password and confirmation password do not match')
      return
    }

    loadingRef.current.continuousStart()

    const authenticationData = {
      Username: email,
      Password: password
    }

    const authenticationDetails = new CognitoIdentityLib.AuthenticationDetails(
      authenticationData
    )

    Logger.start('Authenticate user', { email: email })

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: () => {
        Logger.done('Authenticate user', { email: email })
        signUpUserWithNewEmail()
      },
      onFailure: (err) => {
        loadingRef.current.complete()
        Logger.error('Authenticate user', err)
        setAlertType(ALERT_TYPES.UPDATE_FAILED)
        setErrorMessage(err.message || JSON.stringify(err))

        trackPendo('Failed to update email', { userId: userId, error: err.message })
      },
      mfaRequired: () => {
        Logger.done('Authenticate user (MFA, not important)', { email: email })
        signUpUserWithNewEmail()
      }
    })
  }

  const renderAlert = () => {
    if (alertType === ALERT_TYPES.UPDATE_FAILED) {
      return (
        <div className={[alertStyles.error, alertStyles.render_with_menu, alertStyles.alert].join(' ')}>
          { errorMessage }
        </div>
      )
    } else if (alertType === ALERT_TYPES.UPDATE_SUCCESSFULLY) {
      return (
        <div className={[alertStyles.success, alertStyles.render_with_menu, alertStyles.alert].join(' ')}>
          Update email successfully.
        </div>
      )
    }
  }

  return <div className={styles.container}>
    { renderAlert() }
    <LoadingBar color='#f11946' ref={loadingRef} />

    <Menu highlighted="update_email"/>
    <div>
      <div className={styles.header}>
        <div className={styles.logo}>
          <h1>Blueleaf</h1>
        </div>

        <div className={styles.header_text}>
          <h2>Email Settings</h2>
        </div>
      </div>

      <div className={styles.form_container}>
        <form id="new-password-form" onSubmit={handleSubmit}>
          <div className={styles.fieldset}>
            <label className={styles.label}>
              Email
            </label>
            <input className={styles.input_field} id="email" type="email" value={newEmail} onChange={(e) => setNewEmail(e.target.value)} />
          </div>

          <div className={styles.fieldset}>
            <h3>
              Please enter your password to confirm this change
            </h3>
          </div>

          <div className={styles.fieldset}>
            <label className={styles.label}>
              Password
            </label>
            <input className={styles.input_field} id="password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
          </div>

          <div className={styles.fieldset}>
            <label className={styles.label}>
              Password Confirmation
            </label>

            <input className={styles.input_field} id="password-confirmation" type="password" value={confirmation} onChange={(e) => setConfirmation(e.target.value)} />
          </div>

          <input id="submit-btn" className={styles.submit_btn} type="submit" value="Save" />
        </form>
      </div>

      <Footer />
    </div>
  </div>
}
