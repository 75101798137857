import React, { useState } from 'react'
import * as formStyles from '../SharedCss/form.css'
import { Link } from 'react-router-dom'
import Footer from '../SharedComponents/Footer'
require('dotenv').config()

export default function CredentialsForm ({ authenticate }) {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const handleSubmit = (e) => {
    e.preventDefault()
    authenticate(email, password)
  }

  return (
    <div>
      <div className={formStyles.header}>
        <div className={formStyles.logo}>
          <h1>Blueleaf</h1>
        </div>

        <div className={formStyles.header_text}>
          <h2>Sign In</h2>
          <p>Welcome back!</p>
        </div>
      </div>

      <div className={formStyles.form_container}>
        <form id="login-form" onSubmit={handleSubmit}>
          <div className={formStyles.fieldset}>
            <label className={formStyles.label}>
              Email Address
            </label>
            <input className={formStyles.input_field} type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
          </div>

          <div className={formStyles.fieldset}>
            <label className={formStyles.label}>
              Password
            </label>
            <input className={formStyles.input_field} type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
          </div>

          <input className={formStyles.submit_btn} type="submit" value="Sign In" />
          <Link className={formStyles.link} to="/forgot_password">Forgot your password?</Link>
        </form>
      </div>

      <Footer />
    </div>
  )
}
