import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import * as styles from './index.css'

export default function AdvisorGenericLogin ({ authenticate, advisor }) {
  const year = new Date().getFullYear()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const handleSubmit = (e) => {
    e.preventDefault()
    authenticate(email, password)
  }

  return <div className={styles.generic_container}>
    <div className={styles.generic_container_inner}>
      <div className={styles.header}>
        <div className={styles.pic}>
          <img src={advisor.large_photo_full_url} />
        </div>
        <div className={styles.brand}>
          <div className={styles.sign_in_to}>
            Sign in to...
          </div>
          <div className={styles.advisor_title_container}>
            {advisor.practice_name || advisor.full_name || advisor.email}
          </div>
          <div className={styles.advisor_name}>
            {advisor.full_name_and_advisor_title}
          </div>
        </div>
      </div>

      <div className={styles.form_container}>
        <form id="generic-login-form" onSubmit={handleSubmit}>
          <div className={styles.fieldset}>
            <label className={styles.label}> Email Address </label>

            <input className={styles.input_field} type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
          </div>

          <div className={styles.fieldset}>
            <label className={styles.label}> Password </label>

            <input className={styles.input_field} type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
          </div>

          <div className={styles.fieldset}>
            <input className={styles.submit_btn} type="submit" value="Sign in" />
            <Link className={styles.link} to="/forgot_password">Forgot your password?</Link>
          </div>

        </form>
        <div className={styles.message}>
          <div className={styles.message_header}>
            Welcome back!
          </div>

          <div className={styles.message_body}>
            Having trouble and need help signing in to your account?
            <a className={styles.email_support_link} href="mailto:vipsupport@blueleaf.com"> Email VIP Support </a>
            for assistance.
          </div>
        </div>
      </div>
    </div>

    <div className={styles.footer}>
      {advisor.subdomain}.blueleaf.com is provided by your financial advisor through Blueleaf. © {year} Blueleaf Wealth, Inc. All rights reserved.
      <br/>
      {advisor.disclaimer}
    </div>
  </div>
}
