import React from 'react'
import { Link } from 'react-router-dom'
import { signOut, getAuthenticatedUserData } from '../Auth'
import * as styles from './index.css'
import { signInExternalHost } from '../ExternalHosts'

export default function MyMenu (props) {
  const { highlighted } = props
  const userData = getAuthenticatedUserData()

  const handleSignOut = () => {
    signOut()
  }

  // Signing in again to external host just in case the session has expired over there. The idea
  // is as long as the Cognito token is still valid in the front end app, client should be able to
  // navigate with a valid session to External Host
  const redirectExternalHost = () => {
    signInExternalHost()
  }

  return (
    <div className={styles.menu_container}>
      <div>
        <div className={styles.menu_item}>
          <a onClick={ () => { redirectExternalHost() } }> Back to app</a>
        </div>

        <div className={highlighted === 'update_email' ? [styles.menu_item, styles.highlighted].join(' ') : styles.menu_item}>
          <Link to="/update_email"> Email</Link>
        </div>

        <div className={highlighted === 'change_password' ? [styles.menu_item, styles.highlighted].join(' ') : styles.menu_item}>
          <Link to="/change_password"> Change Password </Link>
        </div>

        <div className={highlighted === 'mfa_settings' ? [styles.menu_item, styles.highlighted].join(' ') : styles.menu_item}>
          <Link to="/mfa_settings"> Mfa Settings</Link>
        </div>

        <div className={[styles.menu_item, styles.last_menu_item].join(' ')}>
          <a onClick={ () => { handleSignOut() } }> Sign Out</a>
        </div>

        <div className={styles.account_name}>
          Editing details for
          <b> { userData.getAttr('email') } </b>
        </div>
      </div>
    </div>
  )
}
