import React, { useState, useRef } from 'react'
import * as alertStyles from '../SharedCss/alert.css'
import * as formStyles from '../SharedCss/form.css'
import { getAuthenticatedUser, getAuthenticatedUserData, fetchUserData } from '../Auth'
import MessageBus from '../MessageBus'
import Menu from '../Menu'
import Logger from '../Logger'
import Footer from '../SharedComponents/Footer'
import ALERT_TYPES from '../SharedConstants/AlertTypes'
import LoadingBar from 'react-top-loading-bar'
import { formatPhoneNumber } from './helpers'
import { trackPendo } from '../pendo'
require('dotenv').config()

export default function MfaSettings (props) {
  const { CognitoIdentityLib } = props
  const cognitoUser = getAuthenticatedUser()
  const userData = getAuthenticatedUserData()
  const currentPhoneNumber = userData.getAttr('phone_number') || ''
  const currentMfaEnabled = userData.isSmsMfaActivated()

  const [mfaEnabled, setMfaEnabled] = useState(currentMfaEnabled)
  const [phone, setPhone] = useState(currentPhoneNumber)
  const [alertType, setAlertType] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const loadingRef = useRef(null)
  const userId = userData.getAttr('custom:bl_id')

  trackPendo('Visit Mfa Settings', { userId: userId })

  const updateUserAttributes = () => {
    return new Promise((resolve, reject) => {
      const formattedPhone = formatPhoneNumber(phone)
      const attribute = {
        Name: 'phone_number',
        Value: formattedPhone
      }

      Logger.start('Update User Attributes', attribute)

      const attributeList = [
        new CognitoIdentityLib.CognitoUserAttribute(attribute)
      ]

      cognitoUser.updateAttributes(attributeList, function (err, result) {
        if (err) {
          reject(err)
        }

        Logger.done('Update User Attributes', attribute)
        setPhone(formattedPhone)
        resolve('done')
      })
    })
  }

  const updateUserMfa = () => {
    return new Promise((resolve, reject) => {
      let settings = {}

      if (mfaEnabled) {
        settings = {
          PreferredMfa: true,
          Enabled: true
        }
      } else {
        settings = {
          Enabled: false
        }
      }

      Logger.start('Update User MFA', settings)

      cognitoUser.setUserMfaPreference(settings, null, (err, result) => {
        if (err) {
          reject(err)
        }

        Logger.done('Update User MFA', settings)
        setAlertType(ALERT_TYPES.UPDATE_SUCCESSFULLY)
        resolve('done')
      })
    })
  }

  const handleSubmit = async (e) => {
    setAlertType('')
    e.preventDefault()

    if (phone === currentPhoneNumber && mfaEnabled === currentMfaEnabled) {
      return
    }

    loadingRef.current.continuousStart()

    if (mfaEnabled && phone.trim().length === 0) {
      setAlertType(ALERT_TYPES.UPDATE_FAILED)
      setErrorMessage('Phone number is required for MFA')
      return
    }

    try {
      if (phone === currentPhoneNumber && mfaEnabled !== currentMfaEnabled) {
        await updateUserMfa()
      } else if (phone !== currentPhoneNumber && mfaEnabled !== currentMfaEnabled) {
        await updateUserAttributes()
        await updateUserMfa()
      } else if (phone !== currentPhoneNumber && mfaEnabled === currentMfaEnabled) {
        await updateUserAttributes()
      }

      loadingRef.current.complete()
      emitUserAttrsChange()
      fetchUserData()
      setAlertType(ALERT_TYPES.UPDATE_SUCCESSFULLY)

      trackPendo('Updated Mfa Settings', { userId: userId })
    } catch (err) {
      loadingRef.current.complete()
      setAlertType(ALERT_TYPES.UPDATE_FAILED)
      setErrorMessage(err.message || JSON.stringify(err))

      trackPendo('Failed to Update Mfa Settings', { userId: userId, error: err.message })
    }
  }

  const emitUserAttrsChange = () => {
    MessageBus.emitUserAttrsChange({
      username: userData.getAttr('sub'),
      bl_id: userData.getAttr('custom:bl_id')
    })
  }

  const renderAlert = () => {
    if (alertType === ALERT_TYPES.UPDATE_FAILED) {
      return (
        <div className={[alertStyles.alert, alertStyles.error, alertStyles.render_with_menu].join(' ')}>
          { errorMessage }
        </div>
      )
    } else if (alertType === ALERT_TYPES.UPDATE_SUCCESSFULLY) {
      return (
        <div className={[alertStyles.alert, alertStyles.success, alertStyles.render_with_menu].join(' ')}>
          Update settings successfully.
        </div>
      )
    }
  }

  return <div className={formStyles.container}>
    { renderAlert() }
    <LoadingBar color='#f11946' ref={loadingRef} />

    <Menu highlighted="mfa_settings" />
    <div>
      <div className={formStyles.header}>
        <div className={formStyles.logo}>
          <h1>Blueleaf</h1>
        </div>

        <div className={formStyles.header_text}>
          <h2>Update your MFA settings</h2>
        </div>
      </div>

      <div className={formStyles.form_container}>
        <form id="new-password-form" onSubmit={handleSubmit}>
          <div className={formStyles.fieldset}>
            <label className={formStyles.label}>
              Phone Number
            </label>
            <input className={formStyles.input_field} id="phone-number" type="text" value={phone} onChange={(e) => setPhone(e.target.value)} />
          </div>

          <div className={formStyles.fieldset}>
            <label className={formStyles.label}>
              Activate SMS mfa
              <input className={formStyles.checkbox} type="checkbox" checked={mfaEnabled} onChange={(e) => setMfaEnabled(e.target.checked)} />
            </label>
          </div>

          <input id="submit-btn" className={formStyles.submit_btn} type="submit" value="Set" />
        </form>
      </div>

      <Footer />
    </div>
  </div>
}
