.generic_container {
  position: relative;
  width: 700px;
  -webkit-box-sizing: border-box;
  padding: 10px;
  margin: 125px auto 0px auto;
  background-image: url(/images/black_8.png);
  background-repeat: repeat;
}

.generic_container_inner {
  margin: 0px;
  padding: 50px 40px;
  height: 500px;
  background-image: url(/images/poweredby_bl_hz_25px.png);
  background-position: 463px 30px;
  background-repeat: no-repeat;
  background-color: white;
  -moz-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 0px 5px rgb(0 0 0 / 20%);
  -o-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 5px rgb(0 0 0 / 20%);
}

.header {
  border-bottom: double 3px #e6e6e6;
  position: relative;
  padding: 0px 0px 18px 0px;
  background: transparent;
}

.pic {
  height: 100px;
  width: 100px;
}

.pic img {
  height: 100px;
  width: 100px;
  -moz-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 0px 3px rgb(0 0 0 / 20%);
  -o-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 3px rgb(0 0 0 / 20%);
}

.brand {
  margin: 20px 0px 0px 120px;
  position: absolute;
  top: 0px;
}


.sign_in_to {
  font-weight: normal;
  font-size: 14px;
  color: #cccccc;
  display: block;
  padding: 0px 0px 5px 0px;
}

.advisor_title_container {
  min-height: 34px;
  font-size: 28px;
  color: #0b313a;
  font-weight: bold;
}

.advisor_name {
  font-weight: 300;
  font-size: 18px;
  color: #0b313a;
  padding: 2px 0px;
  margin: 0;
}

.form_container {
  margin-top: 50px;
}

.form_container form{
  width: 300px;
  float: left;
}

.fieldset {
  margin-bottom: 20px;
}

.label {
  font-size: 14px;
  color: #999999;
  font-weight: normal;
  margin-bottom: 5px;
  display: block;
  width: auto;
  float: none;
  padding: 0;
}

.input_field {
  max-width: 344px;
  display: inline-block;
  border: 1px solid #ccc;
  padding: 6px;
  width: 100%;
  height: 40px;
}

.message {
  width: 210px;
  background-color: #f6f6f6;
  padding: 30px 20px;
  margin: 0px 0px 0px 350px;
  text-shadow: 1px 1px 0px white;
}

.message_header {
  font-family: georgia, times, times new roman, serif;
  font-size: 16px;
  color: #444444;
  line-height: 20px;
  margin: 0px 0px 20px 0px;
  clear: none;
}

.message_body {
  font-size: 14px;
  margin: 0px;
  padding: 0px;
  color: #888888;
  line-height: 18px;
}

.submit_btn {
  margin-top: 1em;
  width: 108px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  -o-border-radius: 6px;
  -ms-border-radius: 6px;
  -khtml-border-radius: 6px;
  border-radius: 6px;
  background-color: #0079de;
  background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #0079de), color-stop(50%, #0079de), color-stop(51%, #0071cf), color-stop(100%, #0071cf));
  background-image: -webkit-linear-gradient(top, #0079de 0%, #0079de 50%, #0071cf 51%, #0071cf 100%);
  background-image: -moz-linear-gradient(top, #0079de 0%, #0079de 50%, #0071cf 51%, #0071cf 100%);
  background-image: -o-linear-gradient(top, #0079de 0%, #0079de 50%, #0071cf 51%, #0071cf 100%);
  background-image: -ms-linear-gradient(top, #0079de 0%, #0079de 50%, #0071cf 51%, #0071cf 100%);
  background-image: linear-gradient(top, #0079de 0%, #0079de 50%, #0071cf 51%, #0071cf 100%);
  cursor: pointer;
  font-family: "helvetica neue", helvetica, arial, sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #FCFCFC;
  text-shadow: 0px -1px 1px #a64767;
  text-align: center;
  line-height: 1;
  padding: 10px 10px 12px 10px;
  border: none;
}

.footer {
  padding: 15px 10px;
  color: #999999;
  font-size: 11px;
  text-align: center;
}

.link {
  margin-left: 20px;
  font-size: 12px;
  color: #16a4de;
  text-decoration: none;
}

.email_support_link {
  color: #16a4de;
  text-decoration: none;
}
